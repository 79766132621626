import React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import { Linkedin } from "styled-icons/bootstrap";
import { AboutUsSection, TeamSection } from "../styles/pages/about-us";
import { Button, Stack } from "@mui/material";
import kima from "../images/home/kima.png";
import gain from "../images/home/gain.png";
import { useTranslation } from "react-i18next";
import { Bg, Text } from "../components/ui";
import useSizeQuery from "../hooks/useSizeQuery";

const AboutUs = () => {
  const { t } = useTranslation("us");
  const { isTablet } = useSizeQuery();

  const team = React.useMemo(
    () => [
      {
        name: "Pol Morral",
        position: "CEO",
        linkedIn: "https://www.linkedin.com/in/pol-morral-dauvergne/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FPOL.png?alt=media&token=781c8967-cb86-4d9e-9309-99ecd6c3db31",
      },
      {
        name: "Juan Carlos Martínez",
        position: "CTO",
        linkedIn: "https://www.linkedin.com/in/juan-carlos-martinez-elizondo/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FCARLITOS.png?alt=media&token=5bbfa00e-a23f-4058-b9a7-adce710fb803",
      },
      {
        name: "José Miguel Carrera",
        position: "Head of Engineering ",
        linkedIn: "https://www.linkedin.com/in/jose-miguel-carrera-pacheco/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FPACHECO.png?alt=media&token=e9471077-2e76-4772-ac37-e7769754ce9b",
      },
      {
        name: "Alexa Villegas",
        position: "COO",
        linkedIn: "https://www.linkedin.com/in/alexa-villegas-236506199/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FALEXA.png?alt=media&token=e8aa08b6-eae2-40f9-bd14-619c6bee6e78",
      },
      {
        name: "Erick Ramírez",
        position: "LaPieza Academy Manager",
        linkedIn: "https://www.linkedin.com/in/erickramirezcolunga/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FERICK.png?alt=media&token=bae325a5-2839-48bb-b6f1-2ced1d55fb0a",
      },
      {
        name: "María Estrada",
        position: "People OPS Coordinator",
        linkedIn:
          "https://www.linkedin.com/in/mar%C3%ADa-elena-estrada-duarte-7753b6210/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FMARY.png?alt=media&token=fa20cd80-b055-4e07-94d1-4bb1d40f714b",
      },
      {
        name: "Desiree Rodríguez",
        position: "Finance Manager",
        linkedIn: "https://www.linkedin.com/in/ydesireers/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FDES.png?alt=media&token=d1f64ad7-c261-45bb-ad24-53958fc95acc",
      },
      {
        name: "Dania Ruiz",
        position: "Product Manager",
        linkedIn: "https://www.linkedin.com/in/dania-ruiz-villa/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FDANIA.png?alt=media&token=9ce2cf06-ada8-4630-be0b-49801e0d1a34",
      },
      {
        name: "José Eduardo Romero",
        position: "Sr. Developer",
        linkedIn: "https://www.linkedin.com/in/je-romero-dev/",
        picture: '',
      },
      {
        name: "Pedro González",
        position: "Sr. Developer",
        linkedIn: "https://www.linkedin.com/in/pedro-gza-hdz/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FPEDRO.png?alt=media&token=ba45a1dc-83fc-46e6-a5ee-8d35ef00a40e",
      },
      {
        name: "Juan Martinez",
        position: "Sr. Developer",
        linkedIn: "https://www.linkedin.com/in/juanmartinezosorio/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FJUAN.png?alt=media&token=7671a63f-66dc-4eaa-89ea-b689d589715f",
      },
      {
        name: "Arantza Gutiérrez",
        position: "Head of Executive Search",
        linkedIn:
          "https://www.linkedin.com/in/arantza-guti%C3%A9rrez-23004a171/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FARA.png?alt=media&token=967425b9-45ff-4441-b16b-c149e347ec69",
      },
      {
        name: "Geraldina Untermann",
        position: "Headhunting Manager",
        linkedIn: "https://www.linkedin.com/in/geraldina-untermann-1a44131a1/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FGERAL.png?alt=media&token=e2ce1961-1afe-476f-adb2-1dc07332d890",
      },
      {
        name: "Ana Pao Solis",
        position: "Headhunting Manager",
        linkedIn: "https://www.linkedin.com/in/anapaolasolismacias/",
        picture: '',
      },
      {
        name: "Andrea Carmona",
        position: "Headhunting Manager",
        linkedIn: "https://www.linkedin.com/in/andrea-carmona-ramos-4b9b16149/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FANDY.png?alt=media&token=a5546789-7296-4620-a795-b0201f59f318",
      },
      {
        name: "Alexia Montoy",
        position: "Customer Success Manager",
        linkedIn: "https://www.linkedin.com/in/alexia-montoy-2489a5221/",
        picture: '',
      },
      {
        name: "Elena Ibarrondo",
        position: "Marketing & Design Manager",
        linkedIn:
          "https://www.linkedin.com/in/elena-ibarrondo-pe%C3%B1a-05a452167/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FELENA.png?alt=media&token=d19b8c6f-6e00-4c7f-808c-0486b047cd24",
      },
      {
        name: "Marijosé Murillo",
        position: "Sales Specialist",
        linkedIn: "https://www.linkedin.com/in/marijose-murillo-24a4791ba/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FMarijose.png?alt=media&token=819158b0-1695-4a4b-9ee4-7525be7e7215",
      },
      {
        name: "Mariangela Osorio",
        position: "Client & collection analyst",
        linkedIn: "https://www.linkedin.com/in/mariangela-marin/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FMariangela.png?alt=media&token=6925b3a8-c0c8-4492-a654-cfe69d129056",
      },
      {
        name: "Alejandra Isosorbe",
        position: "Career Coach",
        linkedIn: "https://www.linkedin.com/in/alejandra-isosorbe/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FALE.png?alt=media&token=f94eb5bb-31c3-4d5e-bb1e-e0a9b420be3d",
      },
      {
        name: "Tulia Valdez",
        position: "Career Coach",
        linkedIn: "https://www.linkedin.com/in/tuliavaldez/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FTULIA.png?alt=media&token=b4d11814-6f0c-4e38-8807-e447965f3c16",
      },
      {
        name: "Matías Romero",
        position: "QA tester",
        linkedIn: "https://www.linkedin.com/in/matias-romero-qa-testing/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FMATI%CC%81AS.png?alt=media&token=d414416f-8224-4b6f-bcb5-b610cd7cfc23",
      },
      {
        name: "Mariana Herrera",
        position: "IT Intern",
        linkedIn:
          "https://www.linkedin.com/in/mariana-herrera-aburto-607556246/?originalSubdomain=mx",
        picture: '',
      },
      {
        name: "Jair García",
        position: "Headhunter Jr",
        linkedIn:
          "https://www.linkedin.com/in/jair-garcia-sanchez-head-hunter/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FJAIR.png?alt=media&token=a8170fd4-6104-405c-87cf-e68346a5ae8d",
      },
      {
        name: "Isabela Reyes",
        position: "Headhunter Jr",
        linkedIn: "https://www.linkedin.com/in/isabela-reyes-gonzales/",
        picture: '',
      },
      {
        name: "Carlos Masias",
        position: "Headhunter",
        linkedIn: "https://www.linkedin.com/in/carlosmesiassuarez/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FCARLOS.png?alt=media&token=dff5c0c1-61e0-40d5-b474-b64143c321d5",
      },
      {
        name: "Yamila Crer",
        position: "Headhunting intern",
        linkedIn: "https://www.linkedin.com/in/yamila-crer/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FYAMILA.png?alt=media&token=0f680ac2-78f1-492e-96fc-14835b722b55",
      },
      {
        name: "Paz Zarza",
        position: "Headhunter",
        linkedIn: "https://www.linkedin.com/in/paz-zarza/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FPAZ.png?alt=media&token=772af349-c8d6-49d8-a933-269cc147c85c",
      },
      {
        name: "Valentina Villaverde",
        position: "Headhunter Jr",
        linkedIn: "https://www.linkedin.com/in/valentina-villaverde/",
        picture: '',
      },
      {
        name: "Fernanda García",
        position: "Headhunter Mid",
        linkedIn: "https://www.linkedin.com/in/mafergarciac/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FMARI%CC%81A%20FERNANDA.png?alt=media&token=c6863728-14d1-4ace-8d5b-c4720b2affb1",
      },
      {
        name: "Alejandro Tesani",
        position: "Headhunting Support intern",
        linkedIn: "https://www.linkedin.com/in/alejandrotesani/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FALEJANDRO.png?alt=media&token=b42d8343-9942-4250-81e1-722fa066dca6",
      },
      {
        name: "Daniela Bongianino",
        position: "Headhunter",
        linkedIn:
          "https://www.linkedin.com/in/mar%C3%ADa-daniela-bongianino1998/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FDANI.png?alt=media&token=9eebfe6d-bed0-4da5-8fba-a579072ac3bb",
      },
      {
        name: "Giuliana Gironzini",
        position: "Headhunter",
        linkedIn: "https://www.linkedin.com/in/giulianagironzini/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FGIU.png?alt=media&token=6f36d8ad-f2b7-4a7c-8919-5a7df2827fb2",
      },
      {
        name: "Valentina Mora",
        position: "Headhunting support & headhunter",
        linkedIn: "https://www.linkedin.com/in/valentinamorar/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FValen%20M.png?alt=media&token=dabe1bb1-90fb-4cc5-89f1-911418266be0",
      },
      {
        name: "Guadalupe Padilla",
        position: "Headhunter Jr",
        linkedIn: "https://www.linkedin.com/in/guadalupe-padilla-51396a22b/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FGUADALUPE.png?alt=media&token=a3d5006a-aa9e-440e-93ce-b89d4f38be6c",
      },
      {
        name: "Lucia Soccini",
        position: "Headhunter Intern",
        linkedIn: "https://www.linkedin.com/in/luciasoccini/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FLUCI%CC%81A.png?alt=media&token=07b2411f-4de8-4de0-b219-5dc46aa9c1fc",
      },
      {
        name: "Josefina Schlaien",
        position: "Headhunter Intern",
        linkedIn: "https://www.linkedin.com/in/josefina-schlaien-945275277/",
        picture: '',
      },
      {
        name: "Melanie Jascovich",
        position: "Headhunter",
        linkedIn:
          "https://www.linkedin.com/in/melanie-aylen-jascovich-7557b2140/",
        picture: '',
      },
      {
        name: "Brisa Chumba",
        position: "Headhunter",
        linkedIn: "https://www.linkedin.com/in/brisa-chumba/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FBRISA.png?alt=media&token=cbebc55e-0541-47d8-a2b1-82258011a82c",
      },
      {
        name: "Cintia Boffa",
        position: "Headhunter Jr",
        linkedIn: "https://www.linkedin.com/in/cintia-boffa/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FCIN.png?alt=media&token=be3eba51-87cc-4bd3-bee7-b458a5126c16",
      },
      {
        name: "Valentina Fernández",
        position: "Headhunter Jr",
        linkedIn:
          "https://www.linkedin.com/in/valentina-fern%C3%A1ndez-b84962225/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FValentina%20Ferna%CC%81ndez.png?alt=media&token=f526d83f-72f8-49ab-8e60-c02d3c714292",
      },
      {
        name: "Agustina Crucianelli",
        position: "Headhunter Intern",
        linkedIn: "https://www.linkedin.com/in/agustina-crucianelli-a58118252/",
        picture: '',
      },
      {
        name: "Sol Petit",
        position: "Headhunter Jr",
        linkedIn: "https://www.linkedin.com/in/sol-petit98/",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fteam%2FSOL.png?alt=media&token=496d7f9d-e256-4c0f-9116-9e775e49f7ce",
      },
      {
        name: "Carla Ward",
        position: "Headhunter Intern",
        linkedIn: "https://www.linkedin.com/in/carla-ward-b52b20274/",
        picture: '',
      },
    ],
    []
  );

  const history = React.useMemo(
    () => [
      {
        id: 1,
        year: 2018,
        title: t("aboutUsItemTitle1"),
        description: t("aboutUsItemDescription1"),
      },
      {
        id: 2,
        year: 2019,
        title: t("aboutUsItemTitle2"),
        description: t("aboutUsItemDescription2"),
      },
      {
        id: 3,
        year: 2020,
        title: t("aboutUsItemTitle3"),
        description: t("aboutUsItemDescription3"),
      },
      {
        id: 4,
        year: 2021,
        title: t("aboutUsItemTitle4"),
        description: t("aboutUsItemDescription4"),
      },
      {
        id: 5,
        year: 2022,
        title: t("aboutUsItemTitle5"),
        description: t("aboutUsItemDescription5"),
        isLast: true,
      },
    ],
    [t]
  );

  return (
    <>
      <Seo
        title="Servicio de reclutamiento Encontramos al mejor talento para tu empresa."
        description="Servicio de reclutamiento especializado en TI y otras áreas ¿Buscas programadores, profesionales de marketing, finanzas, UX/UI?"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Layout>
        <Stack>
          <Bg.Linear>
            <Stack
              direction={isTablet ? "column" : "row"}
              spacing={2}
              padding='80px 100px'
              height="100%"
              alignItems="center"
            >
              <Stack maxWidth="450px" flexGrow={1} spacing={4}>
                <Stack alignItems="start" spacing={2}>
                  <Text.SubTitle
                    textAlign="start"
                    color="primary.orange"
                    fontSize="1.8rem"
                  >
                    {t("heroTitle")}
                  </Text.SubTitle>
                  <Text.Title textAlign="start" fontSize="3rem">
                    {t("heroSubTitle")}
                  </Text.Title>
                </Stack>
                <Text.Body textAlign="start" fontSize="1.6rem">
                  {t("heroSubDescription")}
                </Text.Body>
              </Stack>
              <Stack alignItems="end" flexGrow={1} justifyContent="center">
                <Stack width="400px">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2FaboutUs%2FaboutUs-Header.png?alt=media&token=62c17e1d-7778-4210-b551-18ec205f95a1"
                    alt="Figura de LaPieza"
                  />
                </Stack>
              </Stack>
            </Stack>
          </Bg.Linear>
        </Stack>

        <AboutUsSection>
          <div className="about-section main-landing-container-column">
            <Stack alignItems="start" maxWidth="600px" mb={5}>
              <Text.Title fontSize="3rem">{t("aboutUsTitle")}</Text.Title>
              <Text.Body fontSize="1.6rem" textAlign="start">
                {t("aboutUsDescription")}
              </Text.Body>
            </Stack>
            <div className="about-us-list">
              <ul>
                {history.map((item) => (
                  <li key={item.id}>
                    <div className="dot-container">
                      <span className="dot" />
                      {!item?.isLast ? <span className="dot-line" /> : null}
                    </div>
                    <Stack mb={5} spacing={3}>
                      <Stack direction="row" alignItems="center" spacing={3}>
                        <Stack
                          bgcolor="primary.gray"
                          padding={1}
                          width="70px"
                          borderRadius="20px"
                        >
                          <Text.Title fontSize="1.6rem">{item.year}</Text.Title>
                        </Stack>
                        <Text.Title fontSize="1.6rem">{item.title}</Text.Title>
                      </Stack>
                      <p>{item.description}</p>
                    </Stack>
                  </li>
                ))}
              </ul>
              <figure>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2FaboutUs%2Fteam2024.png?alt=media&token=5e3fc68f-090f-42f7-b7b0-fe2e2c7eb4ff"
                  alt="Equipo de LaPieza 2019"
                />
              </figure>
            </div>
          </div>
        </AboutUsSection>
        <Bg.Gradient>
          <Stack padding="40px 0 0 130px" direction="row" spacing={7}>
            <figure>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2FaboutUs%2FSearch.png?alt=media&token=0aacbf27-baf0-46cf-8252-6801b6cdd4ae"
                alt="Figura de LaPieza"
              />
            </figure>
            <Stack alignItems="start" spacing={3}>
              <Text.Title>{t("hiringTitle")}</Text.Title>
              <Stack maxWidth="500px">
                <Text.Body fontSize="1.6rem" textAlign="start">
                  {t("hiringDescription")}
                </Text.Body>
              </Stack>
              <Button
                href="https://lapieza.io/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "200px",
                }}
              >
                {t("hiringButton")}
              </Button>
            </Stack>
          </Stack>
        </Bg.Gradient>
        <TeamSection>
          <div className="main-landing-container-column">
            <Stack spacing={2} mb={3}>
              <Text.SubTitle>{t("teamTitle")}</Text.SubTitle>
              <Text.Title>{t("teamSubtitle")}</Text.Title>
            </Stack>
            <div className="team-list">
              {team.map((item, idx) => (
                <>
                  {item?.picture && (
                    <article key={`member-item-${idx}`}>
                      <figure>
                        <img src={item.picture} alt="Foto de Pol en LaPieza" />
                      </figure>
                      <div>
                        <a
                          href={item?.linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h5>
                            <Linkedin
                              className="icon-linkedin"
                              size={20}
                              color="#0a66c2"
                            />
                            {item.name}
                          </h5>
                        </a>
                        <p>{item.position}</p>
                      </div>
                    </article>
                  )}
                </>
              ))}
            </div>
          </div>
        </TeamSection>
        <Bg.Linear>
          <Stack padding="80px 100px" direction="row" justifyContent="space-between">
            <Stack spacing={3}>
              <Text.Title>{t("inversorTitle")}</Text.Title>
              <Button
                sx={{
                  width: "230px",
                }}
              >
                <Link
                  to="/contact"
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  {t("inversorButton")}
                </Link>
              </Button>
            </Stack>
            <Stack direction="row" spacing={3}>
              <figure>
                <img src={kima} alt="Logo de Kima Ventures" />
              </figure>
              <figure>
                <img src={gain} alt="Logo de GAIN" />
              </figure>
            </Stack>
          </Stack>
        </Bg.Linear>
      </Layout>
    </>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
